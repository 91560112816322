import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_RESULT } from '../../api/apollo/api';
import { CalculatorsContext, ErrorContext, UserContext } from '../../context';
import { formatDate } from '../../utils';
import { createDraft } from '../../api/rest/list';

const NewAssessment = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { selectedCalculators, createCalculatorList, setLinkedResult } =
    useContext(CalculatorsContext);
  const { changeUserData, companies, user, setDraft, draftId, draft } = useContext(UserContext);
  const { setErrorAlert } = useContext(ErrorContext);

  const calcName = searchParams.get('type');
  const resultId = searchParams.get('resultId');
  const comId = searchParams.get('companyId');
  const entryType = searchParams.get('entryType');

  const [companyId, setCompanyId] = useState(comId);

  const [getResult] = useLazyQuery(GET_USER_RESULT, { variables: { id: resultId } });

  useEffect(() => {
    setDraft(null);
  }, []);

  const onNewDraft = () => {
    try {
      const now = new Date().toISOString().split('.')[0].replace('T', ' ');
      createDraft({
        company_id: companyId,
        company_name: companies[companyId].companyName,
        email: user.email,
        legal_type_id: companies[companyId].legalTypeId,
        industrial_group_id: companies[companyId].industrialGroupId,
        sub_industry_id: companies[companyId].subIndustryId,
        company_date: companies[companyId].establishedDate
          ? formatDate(companies[companyId].establishedDate)
          : now,
        registration_country_id: companies[companyId].countryId,
        zip_code: companies[companyId].zipCode,
        address1: companies[companyId].address1,
        type: calcName,
      })
        .then((res) => {
          const { data, success } = res.data;
          if (success) {
            setDraft({ ...data });
          }
        })
        .catch((err) => {
          setErrorAlert('Cannot create new draft, please thy again later', err);
        });
    } catch (err) {
      setErrorAlert(err.message, err);
    }
  };

  const onErrorMessageClose = () => {
    navigate('/login', { replace: true });
  };

  useEffect(() => {
    if (!selectedCalculators || !draftId) return;
    navigate('/start', { replace: true });
  }, [selectedCalculators, draftId]);

  useEffect(() => {
    if (!companyId) return;
    const company = companies[companyId];
    if (!company) {
      setErrorAlert(
        'The company is not in the list available to the user.',
        null,
        onErrorMessageClose
      );
    } else {
      const isCompanyFilled = (c) =>
        c.userId === user.id &&
        !!c.address1 &&
        !!c.id &&
        !!c.companyName &&
        !!c.countryId &&
        !!c.establishedDate &&
        !!c.zipCode &&
        ((calcName !== 'global' && !!c.industrialGroupId) ||
          (calcName === 'global' && !!c.subIndustryId));
      if (company && isCompanyFilled(company)) {
        onNewDraft();
        changeUserData({ ...user, companyId });
        createCalculatorList(calcName);
      } else {
        setErrorAlert('The company details are not filled.', null, onErrorMessageClose);
      }
    }
  }, [companies, companyId]);

  useEffect(() => {
    if (!resultId) return;
    getResult()
      .then((res) => {
        if (res?.data?.resultById) {
          const result = res.data.resultById;
          const { companyId: newCompanyId } = result;
          setLinkedResult(result);
          setCompanyId(newCompanyId);
        }
      })
      .catch((err) => {
        setErrorAlert(err.message, err, onErrorMessageClose);
      });
  }, [resultId]);

  return <></>;
};

export default NewAssessment;
